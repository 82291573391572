<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/product/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div style="position: absolute;right: 80px;top: 58%;z-index: 99;" @click="nav('contrast')" v-if="this.$i18n.locale == 'zh'">
                <div style="width: 50px;position: relative;cursor: pointer;">
                    <el-image style="width: 100%;max-height: 225px;" :src="require('@/assets/product/duibi.png')"></el-image>
                    <div style="position: absolute;right: -2px;top: -2px;" v-if="duibiProduct.length>0">
                        <div style="width: 20px;height: 20px;background: #E34D59; border-radius: 50%;color: #ffffff;text-align: center;">
                            {{duibiProduct.length}}
                        </div>
                    </div>
                </div>
            </div>
            <el-row :gutter="100">
                <el-col :span="6" :xs="24" style="background:#ffffff;margin-top:2%;padding:0px !important;padding-top:20px;padding-bottom:20px;">
                    <el-tabs v-model='class_id' :tab-position="tabPosition" :class=" $i18n.locale == 'en' || $i18n.locale == 'pt' ? '' : 'tabclass'" @tab-click='tabclick'>
                        <el-tab-pane  v-for="(item,index) in classList" :key="index" :name="item.cat_id+''" :label="item.cat_name"></el-tab-pane>
                    </el-tabs>
                </el-col>
                <el-col :span="18" :xs="24">
                    <div class="content">
                        <div style="display:flex">
                            <div style="line-height:35px;width:50px;margin-top:5px">{{$t('productinfo.series')}}</div> 
                            <div class="xilie">
                                <div style="margin-left:20px">
                                    <el-radio-group v-model="checkboxGroup4" size="small" fill="#0052D9" @change="checkboxchang">
                                        <el-radio-button v-for="(item,index) in xilielist" :label="item.cat_id" :key="index" >{{item.cat_name}}</el-radio-button>
                                    </el-radio-group>
                                </div>
                            </div>
                        </div>
                        <div class="chanpinlist">
                            <el-row :gutter="20">
                                <el-col :span="8" :xs="12" v-for="(item,index) in productlists" :key="index" >
                                    <div style="background:#ffffff;margin-top:20px;cursor: pointer;position: relative;" @click="nav('product/'+item.product_id)">
                                        <div style="position: absolute;right: 10px;top: 10px;z-index: 99;" @click.stop="duibi(item)">
                                            <div style="width: 60px;height: 32px;border-radius: 3px;line-height: 32px;font-size: 14px;text-align: center;color: #266FE8;" :style="{'border':!duibiProduct.includes(item.product_id) ? '1px solid #266FE8' : '1px solid rgba(0, 0, 0, 0.26)','color':!duibiProduct.includes(item.product_id) ? '#266FE8' : 'rgba(0, 0, 0, 0.26)'}">
                                                <div v-if="!duibiProduct.includes(item.product_id)">
                                                    <!-- <i class="el-icon-plus" style="font-size: 8px;"></i> -->
                                                    <span>{{$t('productinfo.duibi')}}</span>
                                                </div>
                                                <div v-if="duibiProduct.includes(item.product_id)" style="font-size: 12px">
                                                    <span>{{$t('productinfo.yiduibi')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chanpinimage" >
                                            <el-image  style="width: 100%;max-height: 225px;" :src="item.images"></el-image>
                                        </div>
                                        <div class="chanpintitle">
                                            {{item.product_name}}
                                        </div>
                                        <div class="chanpinfubiao">
                                             {{item.product_feature}}
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-pagination
                                v-if="total>12"
                                style="margin-top:3%;text-align:center"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="productParams.page"
                                :page-sizes="[12, 18, 30, 48]"
                                :page-size="12"
                                layout="total, prev, pager, next"
                                :pager-count="5"
                                :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { productsClass,productsList} from "@/api/api"; 
export default {
    data(){
        return{
            checkboxGroup4: 0,
            tabPosition:'right',
            classList:[],
            xilielist:[],
            productlists:[],
            productParams:{
                cat_id:'1',
                page:1,
                limit:12
            },
            total:0,
            class_id:'',
            title:'',
            seo:{
                keyword:'',
                description:''
            },
            duibiProduct:[]
        }
    },
    created(){
        let duibiProduct = JSON.parse(sessionStorage.getItem("duibiProduct"))
        if(duibiProduct == null){
            duibiProduct = []
        }
        this.duibiProduct = duibiProduct
        this.title = this.$t('layout.productcenter')+'-'+this.$t('seo.indextitle')
        let class_id = this.$route.query.class_id
        if(class_id != undefined){
            this.class_id = class_id+'' 
        }
        let id = this.$route.query.id
        if(id != undefined){
            this.checkboxGroup4 = id
            this.productParams.cat_id = id
        }
        let page = this.$route.query.page
        if(page != undefined){
            this.productParams.page = page-0
        }
        this.getListClass()
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        sessionStorage.removeItem("duibiProduct")
        this.duibiProduct = []
    },
    mounted() {
        if (this._isMobile()) {
            this.tabPosition = 'top'
        } else {
            this.tabPosition = 'right'
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.seo.keyword
            },
            {
                name: 'description',
                content: this.seo.description
            }
        ]
        }
    },
    methods:{
         _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        async getListClass(){
            const {data:res} = await productsClass()
            this.classList = res
            let arr = [{cat_id:this.class_id,cat_name:this.$t('all')}]
            if(this.class_id != 0){
                if(this.$route.query.id != 0 && this.$route.query.id != undefined){
                    this.productParams.cat_id = this.$route.query.id
                }else{
                    this.productParams.cat_id = this.class_id
                }
                res.forEach((item,index)=>{
                    if(item.cat_id == this.class_id){
                        arr.push(...this.classList[index].children)
                    }
                })
            }else{
                this.productParams.cat_id = this.classList[0].cat_id
                this.class_id = this.classList[0].cat_id+''
                arr.push(...this.classList[0].children)
            }
            this.xilielist = arr
            this.getProductsList()
        },
        async getProductsList(){
            const {data:res} = await productsList(this.productParams)
            res.list.forEach((item,index)=>{
                item.images = process.env.VUE_APP_BASE_API+item.get_image_list[0].product_img
            })
            this.total = res.total
            this.productlists = res.list
            this.seo = res.seo
            history.pushState(null, null, "/productinfo?class_id="+this.class_id+'&page='+this.productParams.page+'&id='+this.checkboxGroup4)
        },
        checkboxchang(data){
            this.checkboxGroup4 = data
            this.productParams.page = 1
            if(data == 0){
                this.productParams.cat_id = this.class_id
                this.getProductsList()
            }else{
                this.productParams.cat_id = data
                this.getProductsList()
            }
        },
        tabclick(data){
            this.checkboxGroup4 = this.class_id
            let arr = [{cat_id:this.class_id,cat_name:this.$t('all')}]
            arr.push(...this.classList[data.index].children)
            this.xilielist = arr
            this.productParams.cat_id = this.classList[data.index].cat_id
            this.productParams.page = 1
            this.getProductsList()
            sessionStorage.removeItem("duibiProduct")
            this.duibiProduct = []
        },
        nav(item){
            if(item == 'contrast'){
                if(this.duibiProduct.length > 0){
                    localStorage.setItem('duibiProduct',JSON.stringify(this.duibiProduct))
                }else{
                    localStorage.setItem('duibiProduct',JSON.stringify([]))
                }
            }
            window.open('/'+item);
        },
        handleSizeChange(value){
            this.productParams.limit = value
            this.getProductsList()
        },
        handleCurrentChange(value){
            this.productParams.page = value
            this.getProductsList()
        },
        duibi(row){
            let duibiProduct = JSON.parse(sessionStorage.getItem("duibiProduct"))
            if(duibiProduct == null){
                duibiProduct = []
                duibiProduct.push(row.product_id)
                sessionStorage.setItem("duibiProduct",JSON.stringify(duibiProduct))
            }else{
                if(duibiProduct.includes(row.product_id)){
                    duibiProduct = duibiProduct.filter((item) => {return item !== row.product_id});
                }else{
                    if(duibiProduct.length >= 4){
                        this.$message.warning('最多选择4个产品进行对比')
                        return
                    }
                    duibiProduct.push(row.product_id)
                }
                sessionStorage.setItem("duibiProduct",JSON.stringify(duibiProduct))
            }
            this.duibiProduct = duibiProduct
        }
    }
}
</script>
<style lang="scss" scoped>
    .tabclass{
        height: 100%;
        width:210px;
    }
    .tabclass >>> .el-tabs__active-bar{
        padding-left:3px;
        height: 0px !important;
        margin-top:15px;
        background: #0052D9;
    }
    .content{
        margin-top: 20px;
        .xilie{
            width: 100%;
            display: flex;
        }
        .chanpinlist{
            .chanpinimage:hover {
                transform: scale(1.2);
            }  
            .chanpinimage{
                width: 225px;
                margin: 0 auto;
            }
            .chanpintitle{
                text-align: center;
                font-family: OPPOSans-H;
                height: 40px;
            }
            .chanpinfubiao{
                text-align: center;
                margin-top: 2%;
                color: rgba(51, 51, 51, 0.6);
                font-size: 12px;
                font-family: SourceHanSansCN-Regular;
                font-weight: 400;
                padding-bottom: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .tabclass{
            height: 100%;
            width: 100%;
        }
        .content{
            .chanpinlist{
                .chanpinimage{
                    width: 100px;
                    margin: 0 auto;
                }
            }
        }
    }
</style>
<style scoped>
    /deep/ .el-tabs__item.is-active{
        color: #0052D9;
    }
    /deep/ .el-tabs__item:hover{
        color: #0052D9;
    }
    /deep/ .el-tabs__active-bar{
        padding-left:3px;
        height: 0px !important;
        margin-top:15px;
        background: #0052D9;
    }
    /deep/ .el-tabs__nav-wrap::after {
        position: static !important;
        height: 0;
    }
    /deep/ .el-tabs__item{
        font-weight: bold;
        height: 50px;
        line-height: 50px;
    }
    /deep/ .el-radio-button__inner{
        border: none;
    }
    /deep/ .el-radio-button:first-child .el-radio-button__inner{
        border: none;
    }
    /deep/ .el-radio-button, .el-radio-button__inner{
        margin-right: 20px;
        margin-top: 10px;
    }
    /deep/ .el-radio-button__inner{
        font-size: 15px;
    }
    /deep/ .el-radio-button.is-checked .el-radio-button__inner{
        background-color: #0052D9;
    }
    /deep/ .el-pager li.active{
        color:#0052D9;
    }
    /deep/ .el-pager li.active{
        color:#0052D9;
    }
    /deep/ .el-pager li:hover{
        color:#0052D9;
    }
    /deep/ .el-radio-button__inner:hover{
        color: #0052D9;
    }
    /deep/ .is-active>.el-radio-button__inner:hover{
        color: #fff !important;
    }
</style>